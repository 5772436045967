import React, { useState } from 'react';
import { Globe, Menu, X, Facebook, Instagram } from 'lucide-react';

const languages = {
  en: {
    home: 'Home',
    ourStory: 'Our Story',
    ourBrands: 'Our Brands',
    ourOffices: 'Our Offices',
    contact: 'Contact',
    storyText: 'Delmar Gıda İnş. San. Ve Tic. Ltd. Şti. was officially registered as a business in 2007, with its foundations laid in 2013. It is established on an 8,000 m² area in Diyarbakır Organized Industrial Zone, with a total of 5,500 m² of enclosed space. The production plan of Maple Chocolate includes nearly 200 product varieties. Maple Chocolate, which produces 32 tons per day, holds ISO 22000, ISO 9001, TSE, and Halal certifications. By collaborating with expert consultants from both domestic and international fields in R&D activities, Maple Chocolate has achieved the desired quality and taste in cocoa butter chocolate products.',
    brandsIntro: 'Discover our exquisite brands',
    officesIntro: 'Find us around the world',
    wantToLearnMore: 'Want to learn more about us?',
    learnMoreText: 'Are you interested in our products? Would you like to receive a supply for your company or yourself? Get in touch with us today!',
    getInTouch: 'GET IN TOUCH',
    contactUs: 'Contact Us',
  },
  tr: {
    home: 'Ana Sayfa',
    ourStory: 'Hikayemiz',
    ourBrands: 'Markalarımız',
    ourOffices: 'Ofislerimiz',
    contact: 'İletişim',
    storyText: '2007 yılında Delmar   Gıda  İnş. San. Ve Tic. Ltd. Şti. Resmen bir iş olarak tescil edilmiştir, ve ilk Temelleri 2013 yılında atılmıştır. Diyarbakır Organize Sanayi Bölgesindeki 8000 m2 arazi üzerine Kurulu ve toplam 5,500 m2 kapalı alana sahiptir. Maple Çikolata’nın üretim planlanmasında 200 çeşide yakın ürün bulunmaktadır. Günlük 32 ton üretim yapan Maple Çikolata ürünleri ISO 22000, ISO 9001, TSE ve Helal sertifikalarına sahiptir. Arge çalışmalarında yurtiçi ve yurt Dışından, konusunda uzman danışmanlarla çalışmalar sürdürülerek kakao yağlı çikolata Ürünlerinden istenilen kalite ve lezzete ulaşmıştır.',
    brandsIntro: 'Muhteşem markalarımızı keşfedin',
    officesIntro: 'Bizi dünya çapında bulun',
    wantToLearnMore: 'Hakkımızda daha fazla bilgi edinmek ister misiniz?',
    learnMoreText: 'Ürünlerimizle ilgileniyor musunuz? Şirketiniz veya kendiniz için tedarik almak ister misiniz? Bugün bizimle iletişime geçin!',
    getInTouch: 'İLETİŞİME GEÇİN',
    contactUs: 'Bize Ulaşın',
  },
  ar: {
    home: 'الرئيسية',
    ourStory: 'قصتنا',
    ourBrands: 'علاماتنا التجارية',
    ourOffices: 'مكاتبنا',
    contact: 'اتصل بنا',
    storyText: 'تم تسجيل شركة دلمار للمواد الغذائية والبناء والصناعة والتجارة المحدودة رسميًا كعمل تجاري في عام 2007، وتم وضع أسسها الأولى في عام 2013. تقع الشركة على مساحة 8000 متر مربع في منطقة ديار بكر الصناعية المنظمة، بمساحة مغطاة إجمالية تبلغ 5,500 متر مربع. تشمل خطة إنتاج شوكولاتة مابل حوالي 200 منتج مختلف. تنتج شوكولاتة مابل 32 طنًا يوميًا وتحمل شهادات ISO 22000 وISO 9001 وTSE والحلال. ومن خلال التعاون مع مستشارين خبراء في الداخل والخارج في مجال البحث والتطوير، حققت شوكولاتة مابل الجودة والمذاق المطلوبين في منتجات الشوكولاتة المصنوعة من زبدة الكاكاو.',
    brandsIntro: 'اكتشف علاماتنا التجارية الرائعة',
    officesIntro: 'جدنا حول العالم',
    wantToLearnMore: 'هل تريد معرفة المزيد عنا؟',
    learnMoreText: 'هل أنت مهتم بمنتجاتنا؟ هل ترغب في الحصول على إمداد لشركتك أو لنفسك؟ تواصل معنا اليوم!',
    getInTouch: 'ابق على تواصل',
    contactUs: 'اتصل بنا',
  },
};

const NavLink = ({ item, t }) => (
  <a
    href={`#${encodeURIComponent(item)}`}
    className="text-red-600 hover:text-red-800 transition duration-300"
  >
    {t(item)}
  </a>
);

const Header = ({ lang, setLang, t }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleLanguageChange = (e) => {
    const newLang = e.target.value;
    if (Object.keys(languages).includes(newLang)) {
      setLang(newLang);
    }
  };

  return (
    <header className="bg-white shadow-md fixed w-full z-10">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <img src="/logo.png" alt="Delmar Gıda" className="h-16" />
        <nav className="hidden md:flex space-x-6">
          {['home', 'ourStory', 'ourBrands', 'ourOffices', 'contact'].map((item) => (
            <NavLink key={item} item={item} t={t} />
          ))}
        </nav>
        <div className="flex items-center space-x-4">
          <div className="flex items-center">
            <Globe className="text-red-600 mr-2" />
            <select
              value={lang}
              onChange={handleLanguageChange}
              className="bg-transparent text-red-600"
            >
              <option value="en">English</option>
              <option value="tr">Türkçe</option>
              <option value="ar">العربية</option>
            </select>
          </div>
          <button 
            onClick={() => setMenuOpen(!menuOpen)} 
            className="md:hidden text-red-600"
            aria-label={menuOpen ? "Close menu" : "Open menu"}
          >
            {menuOpen ? <X /> : <Menu />}
          </button>
        </div>
      </div>
      {menuOpen && (
        <div className="md:hidden bg-white py-4">
          <nav className="flex flex-col items-center space-y-4">
            {['home', 'ourStory', 'ourBrands', 'ourOffices', 'contact'].map((item) => (
              <NavLink key={item} item={item} t={t} />
            ))}
          </nav>
        </div>
      )}
    </header>
  );
};

const Website = () => {
  const [lang, setLang] = useState('en');
  const t = (key) => languages[lang][key] || key;

  return (
    <div className="font-sans bg-white text-gray-800" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
      <Header lang={lang} setLang={setLang} t={t} />

   {/* Hero Section */}
<section id="home" className="relative h-screen bg-cover bg-center" style={{ backgroundImage: 'url("/banner.jpg")' }}>
  <div className="absolute inset-0 bg-black bg-opacity-50"></div>
  <div className="absolute inset-0 flex flex-col items-center justify-center text-center">
    <img src="/logo.png" alt="Delmar Gıda" className="h-36 mb-4" />
    <h1 className="text-6xl font-bold text-white mb-4">Delmar Gıda</h1>
    <p className="text-2xl text-white">İnş. San. Ve Tic. Ltd. Şti.</p>
    <p className="text-2xl text-white">From Diyarbakır to Worldwide</p>
  </div>
</section>


      {/* Our Story */}
      <section id="ourStory" className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-red-600 mb-8 text-center">{t('ourStory')}</h2>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 mb-8 md:mb-0">
              <p className="text-lg leading-relaxed">{t('storyText')}</p>
            </div>
            <div className="md:w-1/2 md:pl-8">
              <img src="/banner2.jpg" alt="Maple Çikolata" className="rounded-lg shadow-xl" />
            </div>
          </div>
        </div>
      </section>

      {/* Our Brands */}
      <section id="ourBrands" className="py-20 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-red-600 mb-8 text-center">{t('ourBrands')}</h2>
          <p className="text-xl text-center mb-12">{t('brandsIntro')}</p>
          <div className="flex flex-col md:flex-row justify-around items-center space-y-8 md:space-y-0">
            
            <div className="text-center">
              <img src="/maple.png" alt="Maple Çikolata" className="mx-auto mb-4 h-32" />
              <a href='https://www.maplecikolata.com/' target='_blank' className="bg-red-600 text-white px-6 py-2 rounded-full hover:bg-red-700 transition duration-300">
                Visit Website
              </a>
            </div>
            
          </div>
        </div>
      </section>

      {/* Our Offices */}
      <section id="ourOffices" className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-red-600 mb-8 text-center">{t('ourOffices')}</h2>
          <p className="text-xl text-center mb-12">{t('officesIntro')}</p>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-3/1 mb-8 md:mb-0">
              <img src="/logo.png" alt="World Map" className="w-full h-20 " />
            </div>
            <div className="md:w-3/2 md:pl-8">
              <div className="space-y-6">
               

                <div>
                  <h3 className="text-2xl font-bold text-red-600 mb-2">Türkiye</h3>
                  <p>ELAZIG KARAYOLU 22 KM 1 O.S.B 1.ETAP 14. CAD. NO:2/1 YENİSEHİR/DİYARBAKIR/TURKEY - YENİŞEHİR/ DİYARBAKIR / TÜRKİYE</p>
                </div>

                

              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="py-20 bg-red-600 text-white">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-8 text-center">{t('wantToLearnMore')}</h2>
          <p className="text-xl text-center mb-12 max-w-2xl mx-auto">{t('learnMoreText')}</p>
          <div className="flex justify-center">
            <button className="bg-white text-red-600 px-8 py-3 rounded-full text-lg font-bold hover:bg-gray-100 transition duration-300">
              {t('getInTouch')}
            </button>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-12">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-8 md:mb-0">
              <h2 className="text-2xl font-bold mb-4">{t('contactUs')}</h2>
              <p>ELAZIG KARAYOLU 22 KM 1 O.S.B 1.ETAP 14. CAD. NO:2/1 YENİSEHİR/DİYARBAKIR/TURKEY - YENİŞEHİR/ DİYARBAKIR / TÜRKİYE</p>
              <p>Tel:(+90) 412 252 22 78</p>
              <p>Fax:(+90) 412 252 22 68</p>
              <p>info@delmargida.com.tr</p>
            </div>
            <div className="flex space-x-4">
              <a href="#" className="text-white hover:text-red-600 transition duration-300" aria-label="Facebook">
                <Facebook size={24} />
              </a>
              <a href="#" className="text-white hover:text-red-600 transition duration-300" aria-label="Instagram">
                <Instagram size={24} />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Website;